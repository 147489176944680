import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime, { stagger } from 'animejs'

import Hamburger from './Hamburger'
import Navi from './Navi'
import SocialIcons from '../SocialIcons'
import Weather from './Weather'

function Header(props) {
	const naviContext = useContext(NaviContext)

	// Animations
	const baseDuration = 200
	// Animating fade in/out
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				translateY: [-75, 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateX: [-40, 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
				delay: anime.stagger(50)
			}, `-=${baseDuration}`)
	}
	const fadeOutLogo = element => {
		const links = element.querySelectorAll('.nav-item')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)'
			})
	}

  return (
		<header id='master-header' className={'c0 x0'}>
			<Hamburger />

			<div className="bg"></div>

			{/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
			naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
			<Transition
				in={naviContext.isActive ? true :	false}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
				mountOnEnter
				unmountOnExit
			>
				<div className="navi-animator c3">
					<Navi />
				</div>
			</Transition>

			<div className="right-side">
				<div className="call-us c0">
					<p><a href="tel:3055327623"><i className="icon icon-contact"></i><span> 305 532 7623</span></a></p>
				</div>
				{/* <div className="social c3">
					<SocialIcons />
				</div> */}
				<Weather />
			</div>
		</header>
  )
}

export default Header