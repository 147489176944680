import React, {useContext} from 'react'

import logoIMG from './logo.png'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

function Logo(props) {
	const naviContext = useContext(NaviContext)

  return (
		<div id="master-logo" className='logo'>
			<img src={logoIMG} alt="Logo" />
		</div>
  )
}

export default Logo